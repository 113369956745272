import { render, staticRenderFns } from "./StructuredWarrantsVsOtherInvestment.vue?vue&type=template&id=03351636&scoped=true&"
import script from "./StructuredWarrantsVsOtherInvestment.js?vue&type=script&lang=js&"
export * from "./StructuredWarrantsVsOtherInvestment.js?vue&type=script&lang=js&"
import style0 from "./StructuredWarrantsVsOtherInvestment.vue?vue&type=style&index=0&id=03351636&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03351636",
  null
  
)

export default component.exports